import {
  BaseLayoutMainStyled,
  BaseLayoutMobileNavStyled,
  BaseLayoutNavStyled,
  BaseLayoutSideMenuWrapperStyled,
  BaseLayoutStyled,
  BaseLayoutMobileLogo,
} from "@/src/ui/components/base_layout/base_layout.styled";
import { Outlet } from "react-router-dom";

import SideMenu from "../side_menu/side_menu";
import { Menu, Logo2, ChevronDown } from "../../assets/icons";
import { ActionButton } from "../buttons/action_button/action_button";
import { useState } from "react";
import MobileMenu from "../mobile_menu/mobile_menu";
import type { DropdownMenuItem } from "../../view_models/ui";
import DropdownMenu from "../dropdown_menu/dropdown_menu";
import { useTranslation } from "react-i18next";
import { history } from "@/src/index";
import { useAppDispatch, useAppSelector } from "../../state";
import { logoutThunk } from "../../state/auth.slice";

interface BaseLayoutProps {
  fullWidth?: boolean;
  navTransparent?: boolean;
}

export const BaseLayout = (props: BaseLayoutProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["commons"]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { user } = useAppSelector((state) => state.auth);
  const onMobileMenuButtonClicked = () => {
    setIsMobileMenuOpen(true);
  };
  const onCloseMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };
  const onLogoClicked = () => {
    history.push("/app");
  };
  const dropdownMenuItems: DropdownMenuItem[] = [
    {
      key: "config",
      title: t("commons:header_dropdown_menu.config"),
      onClick: () => {
        history.push("/config");
      },
    },
    {
      key: "help",
      title: t("commons:header_dropdown_menu.help"),
      onClick: () => {
        window.open("mailto:support-ds@mesoestetic.com");
      },
    },
    {
      key: "faq",
      title: t("commons:header_dropdown_menu.faq"),
      onClick: () => {
        window.open(
          "https://mesoestetic.sharepoint.com/:b:/s/MesoesteticDiagnosisSystem/ET0ZReS0NoxJqJV_sy6Xo98BKfCGvV8QNG_pRbCtDNTGhw?e=JRej68"
        );
      },
    },
    {
      key: "legal",
      title: t("commons:header_dropdown_menu.legal"),
      onClick: () => {
        history.push("/legal-notice");
      },
    },
    {
      key: "privacy_policy",
      title: t("commons:header_dropdown_menu.privacy_policy"),
      onClick: () => {
        history.push("/privacy-policy");
      },
    },
    {
      key: "logout",
      title: t("commons:header_dropdown_menu.logout"),
      onClick: async () => {
        await dispatch(logoutThunk());
        history.replace("/login");
      },
      separator: true,
    },
  ];

  return (
    <>
      <BaseLayoutStyled>
        <BaseLayoutSideMenuWrapperStyled>
          <SideMenu />
        </BaseLayoutSideMenuWrapperStyled>

        <BaseLayoutMobileNavStyled transparent={props.navTransparent}>
          <BaseLayoutMobileLogo onClick={onLogoClicked} aria-hidden>
            <Logo2 />
          </BaseLayoutMobileLogo>
          <ActionButton onClick={onMobileMenuButtonClicked}>
            <Menu />
          </ActionButton>
        </BaseLayoutMobileNavStyled>

        <BaseLayoutMainStyled fullWidth={props.fullWidth}>
          <BaseLayoutNavStyled transparent={props.navTransparent}>
            <DropdownMenu
              items={dropdownMenuItems}
              button={
                <div className="user">
                  <div className="avatar">
                    <span>{"a"}</span>
                  </div>
                  <span className="username">{user?.name}</span>
                  <span className="dropdown-icon">
                    <ChevronDown aria-hidden="true" />
                  </span>
                </div>
              }
            ></DropdownMenu>
          </BaseLayoutNavStyled>
          <main>
            <Outlet />
          </main>
        </BaseLayoutMainStyled>
      </BaseLayoutStyled>

      {isMobileMenuOpen && <MobileMenu onClose={onCloseMobileMenu} />}
    </>
  );
};
