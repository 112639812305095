import { locator } from "@/src/core/app/ioc";
import { TYPES } from "@/src/core/app/ioc/types";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import type { CreatePatientData } from "@/src/core/patients/data/interfaces/create_patient_data";
import type { UpdatePatientData } from "@/src/core/patients/data/interfaces/update_patient_data";
import type { CreatePatientUseCase } from "@/src/core/patients/domain/use_cases/create_patient_use_case";
import type { UpdatePatientUseCase } from "@/src/core/patients/domain/use_cases/update_patient_use_case";
import type { RootState } from "@/src/ui/state";
import { setLoader } from "@/src/ui/state/ui.slice";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { Country } from "../view_models/countries";
import countries from "../view_models/countries";
import type {
  FaceArea,
  FaceAreaDiagnosis,
  StainType,
} from "../view_models/new_patient_step_4";
import {
  NewPatientStep5AllergyAnswer,
  NewPatientStep5DiseasesAnswer,
  NewPatientStep5DisturbancesAnswer,
  NewPatientStep5MedicationsAnswer,
  NewPatientStep5PregnancyAnswer,
  NewPatientStep5RecentTreatmentAnswer,
  NewPatientStep5SensitivityAnswer,
} from "../../../../core/patients/domain/enums/new_patient_step_5";
import { TreatmentProduct } from "@/src/core/patients/domain/enums/treatment_product";
import type {
  IPatientList,
  PatientsSliceState,
} from "../view_models/patients.slice";
import phonePrefixes from "../view_models/phone_prefixes";
import type { Patient } from "@/src/core/patients/domain/models/patient";
import type { GetPatientListUseCase } from "@/src/core/patients/domain/use_cases/get_patient_list_use_case";
import type { DeletePatientUseCase } from "@/src/core/patients/domain/use_cases/delete_patient_use_case";
import type { GetPatients } from "@/src/core/patients/data/interfaces/get_patients";
import type { GetPatientUseCase } from "@/src/core/patients/domain/use_cases/get_patient_use_case";
import { Gender } from "@/src/core/patients/domain/enums/gender";

const initialStateFactory = (): PatientsSliceState => ({
  newPatientFormData: {
    personalData: {
      name: "",
      firstSurname: "",
      secondSurname: "",
      gender: Gender.Male,
      birthDate: new Date().toISOString().split("T")[0],
      phonePrefix: phonePrefixes.find((p) => p.code === "ES")
        ?.dial_code as string,
      phone: "",
      email: "",
      country: countries.find((c) => c === "ES") as Country,
      state: "",
      postalCode: "",
      address: "",
      other: "",
      privacy: false,
      consent: false,
    },
    history: {
      age: "",
      when: "",
      when_comment: "",
      discomfort: "",
      previous_treatment: "",
      previous_treatment_comment: "",
      last_treatment: "",
      result_treatment: "",
      repeat_treatment: "",
      repeat_treatment_comment: "",
      family_background: "",
    },
    lifeHabits: {
      outdoor: "",
      work: "",
      uva_rays: "",
      sunscreen: "",
    },
    diagnosis: {
      areas: [],
      stains: [],
    },
    treatment: {
      phototype: "",
      pregnancy: "",
      diseases: [],
      medications: [],
      allergy: "",
      disturbances: [],
      recentTreatment: "",
      sensitivity: "",
      consent: false,
    },
  },
  newPatientDiagnosisResult: [],
  newPatientTreatmentRecommendation: null,
  newPatientId: null,
  patientList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  patientFilter: {
    page: 1,
    pageSize: 10,
    search: "",
  },
  currentPatient: {
    id: 0,
    name: "",
    firstSurname: "",
    secondSurname: "",
    sex: "",
    birthDate: "",
    phonePrefix: "",
    phone: "",
    email: "",
    state: "",
    address: "",
    extraInfo: "",
    history: "",
    lifeHabits: "",
    diagnoses: [],
    treatment: "",
    treatmentRecommendation: TreatmentProduct.Cosmelan,
    treatmentSessions: [],
  },
});

export const getPatientThunk = createAsyncThunk<
  Patient | undefined,
  { id: number },
  { state: RootState }
>("patients.slice/getPatient", async ({ id }, { dispatch }) => {
  dispatch(setLoader(true));
  try {
    const useCase = await locator.get<IocProvider<GetPatientUseCase>>(
      TYPES.GetPatientUseCase
    )();
    const data = await useCase.execute(id);
    dispatch(setLoader(false));
    return data;
  } catch (error) {
    console.error(error);
  }
});

export const getPatientListThunk = createAsyncThunk<
  IPatientList | undefined,
  Partial<GetPatients>,
  { state: RootState }
>("patients.slice/getPatientList", async (payload, { getState, dispatch }) => {
  dispatch(setLoader(true));
  const filters = {
    pageSize: payload.pageSize || getState().patients.patientFilter.pageSize,
    page: payload?.page || getState().patients.patientFilter.page,
    orderBy: payload?.orderBy || getState().patients.patientFilter.orderBy,
    search: payload?.search || getState().patients.patientFilter.search,
  };
  try {
    const useCase = await locator.get<IocProvider<GetPatientListUseCase>>(
      TYPES.GetPatientListUseCase
    )();
    const data = await useCase.execute(filters);
    dispatch(setLoader(false));
    return data;
  } catch (error) {
    console.log({ error });
  }
});

export const deletePatientThunk = createAsyncThunk<
  void,
  { id: number },
  { state: RootState }
>("patients.slice/deletePatient", async ({ id }, { dispatch }) => {
  dispatch(setLoader(true));
  try {
    const useCase = await locator.get<IocProvider<DeletePatientUseCase>>(
      TYPES.DeletePatientUseCase
    )();
    await useCase.execute(id);
    await getPatientListThunk({});
  } catch (err) {
    console.error(err);
  }
});

export const createPatientThunk = createAsyncThunk<
  Patient,
  void,
  { state: RootState }
>("patients.slice/createPatient", async (payload, { getState, dispatch }) => {
  dispatch(setLoader(true));
  const useCase = await locator.get<IocProvider<CreatePatientUseCase>>(
    TYPES.CreatePatientUseCase
  )();
  const formData = getState().patients.newPatientFormData;
  const createPatientData: CreatePatientData = {
    name: formData.personalData.name,
    first_family_name: formData.personalData.firstSurname,
    second_family_name: formData.personalData.secondSurname,
    gender: formData.personalData.gender,
    birth_date: formData.personalData.birthDate,
    phone_prefix: formData.personalData.phonePrefix,
    phone_number: formData.personalData.phone,
    email: formData.personalData.email,
    country: formData.personalData.country,
    postal_code: formData.personalData.postalCode,
    address: formData.personalData.address,
    extra_info: formData.personalData.other,
    province: formData.personalData.state,
    history: formData.history,
    life_habits: formData.lifeHabits,
    diagnoses: getState().patients.newPatientDiagnosisResult.map((item) => ({
      face_area: item.area,
      diagnosis: item.diagnosis,
    })),
    treatment: {
      phototype: parseInt(formData.treatment.phototype),
      pregnancy:
        formData.treatment.pregnancy === NewPatientStep5PregnancyAnswer.A,
      diseases: formData.treatment.diseases.includes(
        NewPatientStep5DiseasesAnswer.H
      )
        ? []
        : formData.treatment.diseases,
      medications: formData.treatment.medications.includes(
        NewPatientStep5MedicationsAnswer.E
      )
        ? []
        : formData.treatment.medications,
      allergy: formData.treatment.allergy === NewPatientStep5AllergyAnswer.A,
      disturbances: formData.treatment.disturbances.includes(
        NewPatientStep5DisturbancesAnswer.F
      )
        ? []
        : formData.treatment.disturbances,
      recent_treatment:
        formData.treatment.recentTreatment ===
        NewPatientStep5RecentTreatmentAnswer.A,
      sensitivity:
        formData.treatment.sensitivity === NewPatientStep5SensitivityAnswer.A,
    },
  };

  const patient = await useCase
    .execute(createPatientData)
    .finally(() => dispatch(setLoader(false)));

  return patient;
});

export const calculateDianosisThunk = createAsyncThunk<
  { area: FaceArea; diagnosis: FaceAreaDiagnosis }[],
  { areas: FaceArea[]; stains: StainType[][] },
  { state: RootState }
>("patients.slice/calculateDiagnosis", async ({ areas, stains }) => {
  return stains.reduce<{ area: FaceArea; diagnosis: FaceAreaDiagnosis }[]>(
    (acc, areaStains, index) => {
      acc.push(
        ...areaStains.map((stain) => ({
          area: areas[index],
          diagnosis: stain as unknown as FaceAreaDiagnosis,
        }))
      );

      return acc;
    },
    []
  );
});

export const updatePatientThunk = createAsyncThunk<
  void,
  { id: number; data: UpdatePatientData },
  { state: RootState }
>("patients.slice/updatePatient", async (payload) => {
  const useCase = await locator.get<IocProvider<UpdatePatientUseCase>>(
    TYPES.UpdatePatientUseCase
  )();

  await useCase.execute(payload.id, payload.data);
});

const patientsSlice = createSlice({
  name: "patients.slice",
  initialState: initialStateFactory(),
  reducers: {
    resetNewPatientForm: (state) => {
      Object.assign(state, initialStateFactory());
    },
    updateNewPatientForm: (
      state,
      action: PayloadAction<Partial<PatientsSliceState["newPatientFormData"]>>
    ) => {
      Object.assign(state.newPatientFormData, action.payload);
    },
    setPatientSearch: (state, action: PayloadAction<string>) => {
      state.patientFilter.search = action.payload;
    },
    updateCurrentPatient(state, action: PayloadAction<Partial<Patient>>) {
      Object.assign(state.currentPatient, action.payload);
    },
  },
  extraReducers(builder) {
    builder.addCase(getPatientThunk.fulfilled, (state, { payload }) => {
      state.currentPatient = payload
        ? payload
        : initialStateFactory().currentPatient;
    });
    builder.addCase(getPatientListThunk.fulfilled, (state, { payload }) => {
      state.patientList = payload ? payload : initialStateFactory().patientList;
    });
    builder.addCase(createPatientThunk.fulfilled, (state, action) => {
      state.newPatientId = action.payload.id;
      state.newPatientTreatmentRecommendation =
        action.payload.treatmentRecommendation;
    }),
      builder.addCase(calculateDianosisThunk.fulfilled, (state, action) => {
        state.newPatientDiagnosisResult = action.payload;
      });
  },
});

function selectPatientsBase(state: RootState) {
  return state.patients;
}

export const getPatientList = createSelector(
  [selectPatientsBase],
  (state) => state.patientList
);
export const getPatientSearch = createSelector(
  [selectPatientsBase],
  (state) => state.patientFilter.search
);
export const getCurrentPatient = createSelector(
  [selectPatientsBase],
  (state) => state.currentPatient
);
export const getPatientFullName = createSelector(
  [selectPatientsBase],
  (state) =>
    state.currentPatient
      ? `${state.currentPatient.name} ${state.currentPatient.firstSurname} ${
          state.currentPatient.secondSurname || ""
        }`
      : ""
);

export const patientsActions = patientsSlice.actions;
export const { resetNewPatientForm, updateNewPatientForm, setPatientSearch } =
  patientsSlice.actions;
export default patientsSlice.reducer;
